import React from 'react';
import LoginForm from "./LoginForm"
import styled from "styled-components"
import store from "store"
import Nutzungsbedingungen from "../pages/Nutzungsbedingungen";
import MustSubmitDataPage from "../pages/MustSubmitDataPage"

const RunningMessage = styled.div`
  font-size:3em;
  display:flex;
  align-items:center;
  align-content:center;
  text-align:center;
  width:100%;
  height:100%;
  justify-content:center;
`
const stateNotRunning = {running:false}
const stateRunning = {running:true}
const stateRedirectSoon = {redirectSoon: true}
const stateMustSubmitData = {mustSubmitData:true}
const stateShowTerms = {acceptTerms:true}
const stateNoShowTerms = {acceptTerms:false}
const stateNoAuthKey = {authkey:false}


export default
class AuthenticationForm extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      running: false,
      password: null
    }
    let sp = new URLSearchParams(window.location.search);
    let boardid = this.state.boardid = sp.get("id");
    let boardkey = this.state.boardkey = sp.get("key");
    let exportMode = this.state.exportMode = sp.get("api_key");

    this.state.showTitle = sp.get("show_title");
    let previousaccesskey
    if (exportMode) {
      previousaccesskey = exportMode
    } else {
      previousaccesskey = store.get(this.lsAccessKey(boardid, boardkey)) || "";
    }
    this.state.authkey = previousaccesskey;
    this.handleChange = this.handleChange.bind(this);
    this.handleAcceptTerms = this.handleAcceptTerms.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.getMessage = this.getMessage.bind(this)
  }

  openwebmark () {
    window.location.href = "https://www.webmark.eu"
  }

  handleAcceptTerms(accepted) {
    this.setState(stateNoShowTerms)
    if (accepted) {
      const fetchurl = process.env.REACT_APP_SMARBOARD_BASEURI + "/acceptTerms";
      const creds = {"authkey": this.state.authkey}
      const fetchOptions = {
        "method":"POST",
        "mode":"cors",
        "headers":{
          "Content-Type": "application/json"
        },
        body:JSON.stringify(creds)
      }
      fetch(fetchurl, fetchOptions).then((response)=>{
        return response.json()
      }).then((j)=>{
        if (j.ok) {
          //re-authenticate
          this.serverAuth()
        }

      })
    }
    else {
      this.setState(stateNoAuthKey)
    }
  }


  componentDidMount() {
    this.serverAuth();
  }

  render () {
    //return <TextPage header={<TextPageHeader></TextPageHeader>} halfSizeHeader={<TextPageHeader halfsizemode={true}></TextPageHeader>}>Dies ist der Text</TextPage>
    if (this.state.running) {
      return (
        <RunningMessage>Authentifizierung läuft</RunningMessage>
      )
    }
    else {
      if (this.state.redirectsoon) {
        return (<RunningMessage>Authentifizierung abgeschlossen</RunningMessage>)
      }
      else if(this.state.acceptTerms){
        return <Nutzungsbedingungen buttontexts={[{action: this.handleAcceptTerms.bind(this, true), text: "Nutzungsbedingungen aktzeptieren"}, {action: this.handleAcceptTerms.bind(this, false), text: "Logout"}]}/>
      }
      else if (this.state.mustSubmitData) {
        return <MustSubmitDataPage buttontexts={[{action: this.openwebmark, text: "Weiter zu WEBMARK"}]}/>
      }
      else {
        return (
          <LoginForm onChange={this.handleChange} onSubmit={this.handleSubmit} message={this.getMessage()}/>
        )
      }
    }
  }


  lsAccessKey(boardid, boardkey) {
    return boardid + '_' + boardkey;
  }
  getMessage() {
    return this.state.message || '';
  }

  handleChange(event) {
    this.setState({'password': event.target.value});
  }



  /**
   * handle successfull authentication
   * @param authid string
   * @param itemList array of "charts contained in the categories"
   * @param categoryList array of categories
   * @param notificationInterval int the interval in which the server will generate new notifications on changed data
   * */
  handleAuthentication(authid, itemList, categoryList, notificationInterval, sources, displayname) {
    this.setState({authkey:authid} )
    store.set(this.lsAccessKey(this.state.boardid, this.state.boardkey), authid);
    store.set(this.lsAccessKey(this.state.boardid, this.state.boardkey) + "notification", notificationInterval);
    this.props.handleAuthentication(itemList, categoryList, this.state.boardid, !!this.state.exportMode, this.state.showTitle === "true", sources, displayname);
    //propagate to other components
  }
  /**
   * send authentication info to server
   * @param event
   * */
  handleSubmit(event) {
    event.preventDefault()
    this.serverAuth()
  }
  /**
   * implementation of server authentication
   * */
  serverAuth() {
    let fetchurl = process.env.REACT_APP_SMARBOARD_BASEURI + "/boards/" + this.state.boardid + '/' + this.state.boardkey;
    let creds = {
      "password":this.state.password||"" ,
      "authkey": this.state.authkey,
      "version": "3"
    }
    this.setState(stateRunning)
    fetch(fetchurl, {
      method:"POST",
      "mode":"cors",
      "headers": {
        "Content-Type": "application/json"
      },
      body:JSON.stringify(creds)
    }).then(
      (response) => {
        if (!response.ok) {
          console.error(response)
        }
        return response.json();}
    ).then( (s) => {
        try {
          const mustSubmitData = s.mustsubmitdata === true
          const mustAcceptTerms = s.termsaccepted === false
          const clientOld = s.clientold === true
          //server has a newer interface version, we need to reload in hopes of getting a more recent client
          if (clientOld) {
            window.location.reload()
          }
          const hasData = !!s.data
          const authId = s.authid
          if (authId) {
            this.setState({"authkey":authId})
          }
          this.setState(stateNotRunning)
          if (hasData && !mustSubmitData && !mustAcceptTerms) {
            this.setState(stateRedirectSoon)
            this.handleAuthentication(s.authid, s.data, s.categories, s.notificationinterval, s.sources, s.displayname)
          }
          else if (mustAcceptTerms) {
            this.setState(stateShowTerms)
          }
          else if (mustSubmitData) {
            this.setState(stateMustSubmitData)
          }
          else{
            if (this.state.password) {
              this.setState({"message":"Login fehlgeschlagen. Bitte prüfen Sie Ihr Passwort"})
            }
          }
        } catch(x) {
          console.error(x)
          this.setState(stateNotRunning)
          this.setState({"message":"login fehlgeschlagen, bitte prüfen sie passwort, falls weiterhin nicht funktioniert neu laden"})
        }
      }
    ).catch((err) => console.error(err))
  }
}
